<template>
    <div>
      <div class="vendedores-thanks bg-cercles">
        <div class="vendedores-thanks__content">
          <img
            class="vendedores-thanks__favicon"
            src="../../assets/img/logos/favicon.svg"
            alt=""
          />
          <h2 class="vendedores-thanks__title">{{ $t("sellers.thanksPage.title") }}</h2>
          <h4 class="vendedores-thanks__subtitle">
            {{ $t("sellers.thanksPage.content") }}
          </h4>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "Error",
  metaInfo() {
    return {
      title:  this.$t("sellers.thanksPage.title") + " | HelloBB",
      meta: [
         { name: "description", content: this.$t("sellers.thanksPage.content") },
         { name: "robots", content: "noindex"}
      ],
    };
  },
};
</script>
