<template>
  <div class="bb-form-item">
    <!-- Label de l'input  -->
    <div class="bb-form-label" v-if="label" :for="id" :aria-label="label">
      {{ label }}<template v-if="required">*</template>
    </div>

    <!-- Input -->
    <textarea
      class="bb-form-textarea"
      :class="[
        (error || errorLabel) ? 'invalid' : '',
      ]"
      :autofocus="autofocus"
      :cols="cols"
      :disabled="disabled"
      :id="id"
      :maxlength=maxlength
      :name="id"
      :placeholder="placeholder"
      :readonly="readonly"
      :required="required"
      :rows=rows
      :wrap="wrap"
      v-model="inputValue"
      @input="handleInput"
      @focus="$emit('focus')"
    />

    <!-- Error labels, per defecte 'error' i personalitzat 'error Label  -->
    <div class="bb-form-error" v-if="error || errorLabel">
      <template v-if="errorLabel">{{ errorLabel }}</template>
      <template v-else>Lo sentimos, el campo es obligatorio</template>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputTextArea",
  data() {
    return {
      inputValue: this.initialValue || "",
    };
  },
  watch: {
    initialValue(newVal) {
      this.inputValue = newVal;
    },
    deep: true,
  },
  methods: {
    handleInput() {
      this.$emit("input", this.inputValue);
    },
  },
  props: {
    label: {
      type: String,
      default: false,
    },
    initialValue: String,
    error: String,
    errorLabel: String,
    autofocus: String,
    cols: Number,
    disabled: Boolean,
    id: String,
    maxlength: Number,
    name: String,
    placeholder: String,
    readonly: Boolean,
    required: Boolean,
    rows: Number,
    wrap: String,
  },
};
</script>

<style scoped>

.invalid {
  border-color: red !important;
}

</style>
